@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  margin: 0;
  height: 100vh;
  direction: rtl;
  font-family: 'Shabnam', sans-serif;
}
